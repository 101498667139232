.certi-container {
  /* position: relative; */
    height: 40vh;
    background-color: #f8f9fa;
    /* top: 20%; */
  }
  
  .form-wrapper {
    position: relative;
    top: -40%;
    width: 100%;
    max-width: 400px;
    perspective: 1000px;
    /* margin: 2rem; */
  }
  
  .toggle-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    width: 400px;
    background-color:black;
    border-radius: 25px;
    padding: 10px 0;
  }
  
  .toggle-option {
    flex: 1;
    text-align: center;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  
  .toggle-option.active {
    color: #4b0082;
  }
  
  .switch {
    position: absolute;
    width: 50%;
    height: 100%;
    background-color:#4b0082;
    border-radius: 25px;
    transition: transform 0.3s ease;
  }
  
  .switch.left {
    transform: translateX(0);
  }
  
  .switch.right {
    transform: translateX(100%);
  }
  
  .form-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .form-container.flipped {
    transform: rotateY(180deg);
  }
  
  .form-card {
    position: absolute;
    width: 100%;
    backface-visibility: hidden;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
  }
  
  .form-card:first-child {
    transform: rotateY(0deg);
  }
  
  .form-card:last-child {
    transform: rotateY(180deg);
  }
  
  @media (min-width: 200px) {
    .certi-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 1rem;
    }
  
    .form-wrapper {
      margin: auto;
    }
  }
  