.orcadehub-title {
    white-space: nowrap; /* Prevent wrapping */
    filter: brightness(4);
  }
  .oh-logo{
    filter: brightness(5);
  }
  
  @media (max-width: 768px) {
    .orcadehub-title {
      font-size: 1.5rem; /* Decrease the font size for smaller screens */
    }
  }
 