.header {
  padding: 0 120px;
  margin: 0;
  overflow-x: hidden;
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header_logo img {
  width: 70px;
}

.call_mail:hover {
  text-decoration: underline;
}

.first-nav {
  margin-right: -10px;
}

.sec-navbar {
  margin-left: -20px;
}

.second_nav .nav-link {
  color: rgb(7, 7, 90);
}

.second_nav .nav-link:hover {
  color: orange;
}

.second_nav .nav-link.active {
  text-decoration: underline;
  font-size: 20px;
}

.toggle:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

@media (min-width: 1000px) {
  .drop-icon {
    visibility: hidden;
    margin-right: -10px;
  }
}

@media (max-width: 1000px) {
  .header {
    height: 4rem;
  }

  .header .border-bottom {
    border-bottom: none !important;
  }

  .header .header_logo {
    margin-left: -115px;
    margin-top: 10px;
  }

  .header_logo_offcanvas {
    border-bottom: .5px solid rgb(223, 226, 228);
  }

  .header_logo img {
    width: 30px;
  }

  .header_logo .nav-brand {
    font-size: larger !important;
  }

  .first-nav {
    display: none;
    visibility: hidden;
  }

  .second_nav {
    margin-top: -10px;
  }

  .second_nav .nav-link {
    padding-left: 20px;
    border-bottom: .5px solid rgb(228, 231, 235);
    margin-top: -18px;
  }

  .offcanvas-nav {
    height: min-content !important;
  }

  .toggle {
    position: fixed;
    right: 10px;
    top: 15px;
  }
}

@media (max-width: 570px) {
  .toggle {
    position: fixed;
    right: 10px;
    top: 15px;
  }

  .header_logo {
    margin-left: 0;
  }
}

.header.scrolled {
  background-color: black;
}

.header.scrolled .nav-link,
.header.scrolled .call_mail,
.header.scrolled .header_logo_offcanvas,
.header.scrolled .fw-bold {
  color: white !important;
}
