/* Signup.css */

.signup {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: "Arial", sans-serif;
  }
  
  .signup h3 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .signup form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .signup form .MuiTextField-root {
    width: 100%;
  }
  
  .signup button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .signup button:disabled {
    background-color: #a3a3a3;
    cursor: not-allowed;
  }
  
  .signup button:hover {
    background-color: #0056b3;
  }
  
  .signup a {
    text-align: center;
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .signup a:hover {
    text-decoration: underline;
  }
  
  .signup span {
    font-size: 12px;
    color: red;
  }
  