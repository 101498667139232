/* Login.css */

.login {
  width: 100%;
  max-width: 400px; /* Limit the width */
  margin: 0 auto; /* Center the form */
  padding: 20px; /* Space inside the form */
  background-color: #f9f9f9; /* Light background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-family: "Arial", sans-serif; /* Font style */
}

.login h3 {
  text-align: center; /* Center the header */
  margin-bottom: 20px; /* Space below the header */
  color: #333; /* Dark text color */
}

.login form {
  display: flex; /* Flexbox for layout */
  flex-direction: column; /* Vertical arrangement */
  gap: 15px; /* Space between form fields */
}

.login form .MuiTextField-root {
  width: 100%; /* Full width for input fields */
}

.login button {
  background-color: #007bff; /* Button color */
  color: #fff; /* White text color */
  padding: 10px 15px; /* Button padding */
  font-size: 16px; /* Font size */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded button corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background transition */
}

.login button:disabled {
  background-color: #a3a3a3; /* Grey background when disabled */
  cursor: not-allowed; /* Indicate disabled state */
}

.login button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.login a {
  text-align: center; /* Centered link */
  display: block; /* Block-level for spacing */
  margin-top: 10px; /* Space above the link */
  color: #007bff; /* Link color */
  text-decoration: none; /* Remove underline */
}

.login a:hover {
  text-decoration: underline; /* Underline on hover */
}

.login span {
  font-size: 12px; /* Smaller font for error messages */
  color: red; /* Red color for errors */
}

/* Responsive Design */
@media (max-width: 480px) {
  .login {
      padding: 15px; /* Less padding on smaller screens */
  }
}
