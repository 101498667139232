.cloud-highlight {
    display: inline-block;
    padding: 10px 20px;
    background: linear-gradient(145deg, #f0f0f0, #cacaca);
    box-shadow: 8px 8px 16px #aaaaaa, -8px -8px 16px #ffffff;
    border-radius: 30px;
    color: #6A0DAD;
    font-weight: bold;
    font-size: 1.5em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  