.free-card {
    width: 100vw;
    height: 90vh;
    background-color: #6a0dad; /* Purple background color */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }
  
  .free-card-content {
    text-align: center;
    max-width: 90%;
    position: relative;
    padding: 20px;
  }
  
  .free-card h1 {
    font-size: 2.5rem;
  }
  
  .free-card h2 {
    font-size: 2rem;
    margin: 20px 0;
  }
  
  .free-card p {
    font-size: 1.2rem;
    margin: 20px 0;
  }
  
  .whatsapp-button {
    background-color: #25d366; /* WhatsApp green color */
    border: none;
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .whatsapp-button:hover {
    background-color: #20b858; /* Slightly darker green on hover */
  }
  
  .free-logo {
    position: absolute;
    top: -50px;
    right: -50px;
    width: 200px;
    height: auto;
    transition: all 0.5s ease-in-out;
  }
  
  /* Animations */
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideIn {
    from { transform: translateX(-100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  @keyframes zoomIn {
    from { transform: scale(0); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  
  @keyframes bounceIn {
    from { transform: scale(0.5); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  
  .fade-in {
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .slide-in {
    animation: slideIn 1.5s ease-in-out;
  }
  
  .zoom-in {
    animation: zoomIn 1.5s ease-in-out;
  }
  
  .bounce-in {
    animation: bounceIn 1.5s ease-in-out;
  }
  
  /* Responsive Design */
  
  @media (max-width: 980px) {
    .free-card {
      height: auto;
      padding: 10px;
    }
  
    .free-card-content {
      padding: 10px;
    }
  
    .free-card h1 {
      font-size: 2rem;
    }
  
    .free-card h2 {
      font-size: 1.5rem;
    }
  
    .free-card p {
      font-size: 1rem;
    }
  
    .whatsapp-button {
      font-size: 1rem;
      padding: 10px 20px;
    }
  
    .free-logo {
      width: 70px;
      display: none;
    }
  }
  